import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';

import Navbar from '../Navbar';
import Footer from '../Footer';
import { fonts, colors } from '../constants';

import PageWrapper from '../../views/PageWrapper';

const GlobalStyle = createGlobalStyle`
  html {
    font-family: ${fonts.body};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${fonts.heading};
  }

  em {
    color: ${colors.red};
    font-style: normal;
  }

  .uk-lightbox {
    background: rgba(25, 25, 25, 0.7);

    .uk-position-top {
      position: absolute !important;
      top: 0;
      background: transparent;
    }

    .uk-lightbox-toolbar-icon {
      color: rgba(255, 255, 255, 0.8);
    }
  }
`;

function Layout({ nav, unwrap, children }) {
  return (
    <React.Fragment>
      <GlobalStyle />
      <header>{nav}</header>
      {unwrap ? (
        <main>{children}</main>
      ) : (
        <main>
          <PageWrapper />
          {children}
          <PageWrapper bottom />
        </main>
      )}
      <footer>
        <Footer />
      </footer>
    </React.Fragment>
  );
}

Layout.defaultProps = {
  nav: <Navbar />,
  unwrap: false,
};

Layout.propTypes = {
  nav: PropTypes.node,
  unwrap: PropTypes.bool,
};

export default Layout;
