import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import Section from '../Section';
import { colors } from '../constants';

import logo from '../../images/logo-nav.png';

const DescriptionList = styled.dl`
  & > dt {
    color: #fff;
  }
`;

const Logo = styled.img`
  width: 100px;
`;

function Footer() {
  const currentYear = new Date().getFullYear();
  const { site } = useStaticQuery(SITE_QUERY);
  const { siteMetadata } = site;

  const lastPath = (url) => url.substr(url.lastIndexOf('/') + 1);

  return (
    <Section
      className="uk-light"
      containerClass="uk-container-small"
      backgroundColor={colors.secondaryColor}
      light
    >
      <div uk-grid="" className="uk-grid uk-grid-small uk-child-width-1-4@s">
        <div>
          <Logo
            className="uk-margin-remove-bottom"
            src={logo}
            alt={siteMetadata.generalInfo.name}
            uk-img=""
          />
          <p className="uk-margin-remove-top">
            &copy; {siteMetadata.generalInfo.initYear}&nbsp;-&nbsp;
            {currentYear}
          </p>
        </div>

        <div>
          <DescriptionList className="uk-description-list">
            <dt>Social</dt>
            <dd>
              <ul className="uk-list uk-margin-remove-bottom">
                <li>
                  <FontAwesomeIcon
                    icon={faFacebookSquare}
                    color={colors.facebook}
                    className="uk-margin-small-right"
                  />
                  <OutboundLink
                    href={siteMetadata.social.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {lastPath(siteMetadata.social.facebook)}
                  </OutboundLink>
                </li>
                <li className="uk-margin-remove-top">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    color={colors.instagram}
                    className="uk-margin-small-right"
                  />
                  <OutboundLink
                    href={siteMetadata.social.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {lastPath(siteMetadata.social.instagram)}
                  </OutboundLink>
                </li>
              </ul>
            </dd>
          </DescriptionList>
        </div>

        <div>
          <DescriptionList className="uk-description-list">
            <dt>Email</dt>
            <dd>
              <OutboundLink
                className="uk-link-text"
                href={`mailto:${siteMetadata.contactInfo.email}`}
              >
                {siteMetadata.contactInfo.email}
              </OutboundLink>
            </dd>
          </DescriptionList>
        </div>

        <div>
          <DescriptionList className="uk-description-list">
            <dt>Dirección</dt>
            <dd>
              <ul className="uk-list">
                <li>{siteMetadata.addressInfo.addressLine}</li>
                <li className="uk-margin-remove-top">
                  {`${siteMetadata.addressInfo.zip} ${siteMetadata.addressInfo.location}`}
                </li>
              </ul>
            </dd>
          </DescriptionList>
        </div>
      </div>
    </Section>
  );
}

const SITE_QUERY = graphql`
  query {
    site {
      siteMetadata {
        generalInfo {
          name
          initYear
        }
        addressInfo {
          addressLine
          zip
          location
        }
        contactInfo {
          email
        }
        social {
          facebook
          instagram
        }
      }
    }
  }
`;

export default Footer;
