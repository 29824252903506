import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';

import NavList from './NavList';
import NavOffCanvas from './NavOffCanvas';
import { colors } from '../constants';

import logo from '../../images/logo-nav.png';

const Nav = styled.nav`
  &&& {
    background: inherit;
  }
`;

function Sticky({ animated, children, ...props }) {
  const ukStickyProp = animated
    ? 'animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-invisible; top: 150'
    : '';
  return (
    <div uk-sticky={ukStickyProp} {...props}>
      {children}
    </div>
  );
}

const StyledSticky = styled(Sticky)`
  &&& {
    background: ${colors.primaryColor};
  }
`;

const StyledLogo = styled.img`
  width: 100px;
`;

function Navbar({ animated, navLinks }) {
  const { site } = useStaticQuery(SITE_QUERY);
  const { siteMetadata } = site;
  return (
    <div className="uk-position-top">
      <StyledSticky className="uk-box-shadow-medium" animated={animated}>
        <Nav
          className="uk-navbar uk-navbar-container uk-container uk-light"
          uk-navbar=""
        >
          <div className="uk-navbar-left">
            <div className="uk-navbar-item uk-hidden@m">
              <NavOffCanvas navLinks={navLinks} />
            </div>
            <div className="uk-navbar-item">
              <Link to="/">
                <StyledLogo src={logo} alt={siteMetadata.title} uk-img="" />
              </Link>
            </div>

            <div className="uk-visible@m">
              <NavList navLinks={navLinks} />
            </div>
          </div>
        </Nav>
      </StyledSticky>
    </div>
  );
}

const SITE_QUERY = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

Navbar.defaultProps = {
  animated: false,
  navLinks: [
    {
      label: 'Inicio',
      href: '/',
    },
    {
      label: 'Productos',
      href: '/productos/',
    },
    {
      label: 'Contacto',
      href: '/contacto/',
    },
  ],
};

Navbar.propTypes = {
  animated: PropTypes.bool,
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ),
};

export default Navbar;
