export const colors = {
  primaryColor: '#5cae40',
  secondaryColor: '#222222',
  lightBlue: '#2979ff',
  blue: '#3d5afe',
  green: '#00e676',
  lightRed: '#ff1a51',
  red: '#ff1744',
  purple: '#ae4093',
  violet: '#8964ab',
  lightGray: '#999999',
  gray: '#e5e5e5',

  facebook: '#3c5a99',
  instagram: '#c13584',
};

export const fonts = {
  heading:
    '"Handlee", cursive, "Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  body:
    '"Lato", sans-serif, "Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
};
