import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookSquare,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import NavLink from './NavLink';
import { colors, fonts } from '../constants';

import logo from '../../images/logo-nav-color.png';

const StyledOffCanvas = styled.div`
  &&& {
    background-color: #fff;
    padding-top: 75px;
    .uk-offcanvas-close {
      color: ${colors.secondaryColor};
    }
    ul.uk-nav {
      li {
        font-size: 1rem;
        padding: 0;
        margin-bottom: 10px;
        padding-left: 5px;
        color: ${colors.secondaryColor};

        &.uk-nav-header {
          font-family: ${fonts.heading};
          font-size: 2rem;
          text-transform: none;
          color: ${colors.primaryColor};
          border-bottom: 1px solid #e5e5e5;
        }

        &.uk-active {
          border-left: 1px solid ${colors.primaryColor};
        }

        & > a.uk-active {
          border-left: 1px solid ${colors.primaryColor};
          padding-left: 5px;
          margin-left: -5px;
        }

        a {
          display: inline-block;
          padding: 0;
          color: ${colors.secondaryColor};
        }
      }
    }
  }
`;

const Logo = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const Li = styled.li`
  & > a {
    width: 100%;
  }
`;

function NavOffCanvas({ navLinks }) {
  const { site } = useStaticQuery(SITE_QUERY);
  const { generalInfo, contactInfo, social } = site.siteMetadata;

  /* Quick fix for menu duplication, thanks UIKit... */
  const offCanvasRef = useRef(null);
  useEffect(() => {
    const currentOffCanvas = offCanvasRef.current;
    return () => {
      currentOffCanvas && currentOffCanvas.remove();
    };
  }, []);

  const randomId = `id${
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  }`;

  const lastPath = (url) => url.substr(url.lastIndexOf('/') + 1);

  return (
    <React.Fragment>
      <button
        className="uk-button uk-button-link"
        type="button"
        aria-label="Menú"
        aria-controls={randomId}
        uk-toggle={`target: #${randomId}`}
      >
        <FontAwesomeIcon color="#fff" icon={faBars} size="lg" />
      </button>

      <div id={randomId} ref={offCanvasRef} uk-offcanvas="overlay: true">
        <StyledOffCanvas className="uk-offcanvas-bar">
          <button
            className="uk-offcanvas-close"
            type="button"
            aria-label="Cerrar menú"
            uk-close=""
          />

          <Logo>
            <img
              className="uk-width-small"
              src={logo}
              alt={generalInfo.name}
              uk-img=""
            />
          </Logo>

          <ul
            className="uk-nav uk-nav-default"
            uk-scrollspy-nav="closest: li; cls: uk-active; scroll: true; offset: 50"
          >
            <li className="uk-nav-header">Menú</li>
            {navLinks.map((link, i) => {
              return (
                <Li key={i}>
                  <NavLink
                    offCanvasId={randomId}
                    label={link.label}
                    href={link.href}
                  />
                </Li>
              );
            })}
            <li className="uk-nav-header">Contacto</li>
            <li>
              <FontAwesomeIcon
                icon={faEnvelopeSquare}
                color={colors.primaryColor}
                className="uk-margin-small-right"
              />
              <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
            </li>
            <li>
              <FontAwesomeIcon
                icon={faFacebookSquare}
                color={colors.facebook}
                className="uk-margin-small-right"
              />
              <OutboundLink
                href={social.facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                {lastPath(social.facebook)}
              </OutboundLink>
            </li>
            <li>
              <FontAwesomeIcon
                icon={faInstagram}
                color={colors.instagram}
                className="uk-margin-small-right"
              />
              <OutboundLink
                href={social.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                {lastPath(social.instagram)}
              </OutboundLink>
            </li>
          </ul>
        </StyledOffCanvas>
      </div>
    </React.Fragment>
  );
}

const SITE_QUERY = graphql`
  query {
    site {
      siteMetadata {
        generalInfo {
          name
        }
        contactInfo {
          email
        }
        social {
          facebook
          instagram
        }
      }
    }
  }
`;

NavOffCanvas.propTypes = {
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default NavOffCanvas;
