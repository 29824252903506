import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import { colors } from '../constants';

const StyledSection = styled.div`
  background-color: ${(props) => props.backgroundColor};
`;

const StyledHeading = styled.h1`
  font-size: ${(props) => (props.smallTitle ? '3rem' : '4rem')};
  color: ${(props) => (props.light ? '#fff' : '#000')};
  && > ::before,
  && > ::after {
    border-bottom: calc(0.2px + 0.05em) dashed
      ${(props) => (props.light ? '#fff' : colors.gray)};
  }
`;

function Section({
  title,
  smallTitle,
  sectionClass,
  containerClass,
  backgroundColor,
  light,
  className,
  children,
  ...props
}) {
  const sectionClassNames = classnames(
    'uk-section',
    'uk-section-default',
    sectionClass,
    className
  );
  const containerClassNames = classnames('uk-container', containerClass);

  return (
    <StyledSection
      className={sectionClassNames}
      backgroundColor={backgroundColor}
      {...props}
    >
      <div className={containerClassNames}>
        {title && (
          <div className="uk-margin-medium-bottom">
            <StyledHeading
              className="uk-heading-line uk-text-center uk-text-bold"
              smallTitle={smallTitle}
              light={light}
            >
              <span>{title}</span>
            </StyledHeading>
          </div>
        )}
        {children}
      </div>
    </StyledSection>
  );
}

Section.defaultProps = {
  className: null,
  sectionClass: null,
  containerClass: null,
  backgroundColor: 'transparent',
  light: false,
  title: null,
  smallTitle: false,
};

Section.propTypes = {
  className: PropTypes.string,
  sectionClass: PropTypes.oneOf([
    'uk-section-xsmall',
    'uk-section-small',
    'uk-section-large',
    'uk-section-xlarge',
  ]),
  containerClass: PropTypes.oneOf([
    'uk-container-xsmall',
    'uk-container-small',
    'uk-container-large',
    'uk-container-expand',
  ]),
  backgroundColor: PropTypes.string,
  light: PropTypes.bool,
  title: PropTypes.string,
  smallTitle: PropTypes.bool,
};

export default Section;
