import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

const StyledDiv = styled.div`
  height: 25vh;
  position: relative;
  ${(props) =>
    css`
      &::before {
        display: block;
        content: "";
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background-image: url('${props.background}');
        background-position: ${props.bottom ? 'top' : 'bottom'} center;
        opacity: 0.2;
      }
    `}
`;

function PageWrapper({ bottom }) {
  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "pattern.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  );
  return (
    <StyledDiv
      background={background.childImageSharp.fluid.src}
      bottom={bottom}
    />
  );
}

PageWrapper.defaultProps = {
  bottom: false,
};

PageWrapper.propTypes = {
  bottom: PropTypes.bool,
};

export default PageWrapper;
