import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ lang, meta, title, description }) {
  const { site } = useStaticQuery(SITE_QUERY);
  const { pathname } = useLocation();

  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
    author,
    generalInfo,
    facebook,
  } = site.siteMetadata;

  const seo = {
    title: title ? `${title} | ${defaultTitle}` : defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
        prefix: 'og: http://ogp.me/ns#',
      }}
      title={seo.title}
      meta={meta}
    >
      <meta charset="utf-8" />
      <meta http-equiv="content-language" content={lang} />
      <meta name="description" content={seo.description} />
      <meta name="author" content={author} />

      <meta property="fb:app_id" content={facebook.fbAppId} />
      <meta property="fb:page_id" content={facebook.fbPageId} />
      <meta property="og:locale" content="es_AR" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:site_name" content={generalInfo.name} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={seo.url} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={`${siteUrl}/og-preview-1.jpg`} />
      <meta
        property="og:image:secure_url"
        content={`${siteUrl}/og-preview-1.jpg`}
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content="Liberi" />
      <meta property="og:image" content={`${siteUrl}/og-preview-2.jpg`} />
      <meta
        property="og:image:secure_url"
        content={`${siteUrl}/og-preview-2.jpg`}
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="300" />
      <meta property="og:image:height" content="300" />
      <meta property="og:image:alt" content="Liberi" />
    </Helmet>
  );
}

const SITE_QUERY = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
        author
        generalInfo {
          name
        }
        facebook {
          fbAppId
          fbPageId
        }
      }
    }
  }
`;

SEO.defaultProps = {
  lang: 'es',
  title: null,
  description: '',
  meta: [],
};

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      property: PropTypes.string,
      itemprop: PropTypes.string,
      content: PropTypes.string.isRequired,
    })
  ),
};

export default SEO;
