import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NavLink from './NavLink';

const Li = styled.li`
  && > a.uk-active {
    color: #fff;
  }
`;

function NavList({ navLinks }) {
  return (
    <ul
      className="uk-navbar-nav"
      uk-scrollspy-nav="closest: li; cls: uk-active; scroll: true; offset: 50"
    >
      {navLinks.map((link, i) => {
        return (
          <Li key={i}>
            <NavLink label={link.label} href={link.href} />
          </Li>
        );
      })}
    </ul>
  );
}

NavList.propTypes = {
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default NavList;
