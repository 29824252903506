import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

function NavLink({ label, href, offCanvasId }) {
  const isHash = href.startsWith('#');
  const attr = offCanvasId ? { 'uk-toggle': `target: #${offCanvasId}` } : {};
  return isHash ? (
    <a {...attr} href={href}>
      {label}
    </a>
  ) : (
    <Link to={href} activeClassName="uk-active" partiallyActive={href !== '/'}>
      {label}
    </Link>
  );
}

NavLink.defaultProps = {
  offCanvasId: null,
};

NavLink.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  offCanvasId: PropTypes.string,
};

export default NavLink;
